$mode: 'all';
@import 'lib/compass-mixins/lib/compass';

@import 'config/index';
@import 'typography/index';
@import 'mixins/index';

$svg-gradient-shim-threshold: 100;
* {
  @include box-sizing(border-box);
}

@import 'modules/utils/_normalize';
@import 'modules/utils/_clearfix';
@import 'modules/utils/_grid';
@import 'modules/_text';
@import 'modules/_backgrounds';
@import 'modules/_dimensions';
@import 'modules/_rainbow';
@import 'modules/_buttons';
@import 'modules/_inputs';
@import 'modules/_rating';
@import 'modules/_icons';
@import 'modules/_autocomplete';

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: $text-color;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #0093df;
}

img {
  border: 0;
}

.header {
  height: 65px;
  background-color: #fa0;
  @include background-image(linear-gradient(to bottom, #ffc13e, #f09400));
}

.header--content {
  width: 960px;
  margin: 0 auto;
}

.logo {
  background: url(/assets/images/logo.svg);
  background-size: 239px 50px;
  display: block;
  width: 239px;
  height: 50px;
  margin-top: 4px;
  position: absolute;
}

.error-page {
  margin: 0 auto;
  padding: 70px 20px;
  max-width: 400px;
}

.error-page--wide {
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 800px;
}

.search-input {
  border: 1px solid #ccc;
  width: 100%;
  background: #fff;
  outline: 0;
  padding: 0 9px;
  line-height: 1.2em;
  height: 31px;
  font-size: 13px;
  vertical-align: top;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0), 1px 1px 2px rgba(0, 0, 0, 0.07) inset;
  &:focus {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 0 rgba(0, 0, 0, 0) inset;
    border-color: #ea0;
  }
  @include transition(all linear 0.1s);
  @include border-radius(3px);
}
